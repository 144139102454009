import { SVGProps } from 'react'

export const HousePlaceholderIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={84} height={79} viewBox="0 0 86 80" fill="none" {...props}>
    <path
      fill="#DADBDD"
      fillRule="evenodd"
      d="m69.86 22.669 12.73 12.76a4.834 4.834 0 0 1 0 6.824 4.796 4.796 0 0 1-3.406 1.413 4.8 4.8 0 0 1-3.405-1.413L43.945 10.346c-1.032-1.03-2.859-1.03-3.896.003L8.221 42.253a4.806 4.806 0 0 1-6.812 0 4.836 4.836 0 0 1 0-6.825L33.241 3.526c4.675-4.695 12.824-4.703 17.51-.008l9.48 9.5V7.93a4.818 4.818 0 0 1 4.81-4.825A4.82 4.82 0 0 1 69.86 7.93v14.74Zm-29.535-3.16a2.36 2.36 0 0 1 3.344 0l27.996 28.055c.444.443.692 1.047.692 1.678v20.462c0 4.802-3.884 8.695-8.674 8.695H49.82V56.882H34.17v21.517H20.308c-4.79 0-8.675-3.893-8.675-8.695V49.242c0-.63.25-1.235.693-1.678l27.999-28.055Z"
      clipRule="evenodd"
    />
  </svg>
)
export default HousePlaceholderIcon
