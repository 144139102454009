import { useMemo } from 'react'
import { GraphQLClient } from 'graphql-request'
import { API_ENDPOINT } from '../constants'
import { UseQueryOptions } from '@tanstack/react-query'
import { BasicCountFacet, Query, ResidentialPropertyListingConnection } from '../generated/graphql/types'
import {
  GetHomeListingsQuery,
  GetHomeListingsQueryVariables,
  useGetHomeListingsQuery,
} from '../generated/graphql/get-home-listings.graphql'
import { GetUsersQuery } from '../generated/graphql/get-users.graphql'
import { ClientError } from 'graphql-request'

// ------------------------------------------------------------------------------------------------------------
//
// NOTE:  Our hooks make use of useMemo() because they all return an object and if we return a new object
//        every time (even when the data doesn't change) any useEffects() that are dependent on this data
//        will re-run and potentially cause infinite render loops.
//
// ------------------------------------------------------------------------------------------------------------

export function useGetHomeListings(
  variables: GetHomeListingsQueryVariables,
  options?: UseQueryOptions<GetUsersQuery, ClientError, Query>
) {
  const { data, isFetching } = useGetHomeListingsQuery<GetHomeListingsQuery, ClientError>(
    new GraphQLClient(API_ENDPOINT),
    variables
  )

  return useMemo(() => {
    return {
      listingsTabsFacets:
        (data?.listingsTabsCounts?.facetGroups?.find((facetGroup) => facetGroup?.name === 'userListingSection')
          ?.facets as BasicCountFacet[]) || [],
      recentlyListed: data?.recentlyListed as ResidentialPropertyListingConnection,
      recentlySold: data?.recentlySold as ResidentialPropertyListingConnection,
      browseByPlaces: data?.browseByPlaces,
      isFetching,
      regions: data?.regions?.placeTypeAggregations[0].aggregations,
    }
  }, [data, isFetching])
}
