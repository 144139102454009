import {
  GeocodedAddress,
  Place,
  PlaceAutoCompleteSuggestion,
  PlaceAutoCompleteSuggestionInfo,
  PlaceType,
} from '@unreserved-frontend-v2/api/generated/graphql/types'

export function addressToString(address: GeocodedAddress | undefined) {
  if (!address) {
    return '-'
  }

  return `${address.address1}, ${address.city}, ${address.region}`
}

/**
 * Direct inverse of `addressToString` and relies on it 1:1
 */
export function stringAddressToObject(
  addressString?: string
): Pick<GeocodedAddress, 'address1' | 'city' | 'region'> | undefined {
  if (!addressString) {
    return undefined
  }

  const [address1, city, region] = addressString.split(', ')

  return {
    address1,
    city,
    region,
  }
}

export function placeToString(place: Place | undefined) {
  if (!place) {
    return '-'
  }

  //
  //  TODO: Change all instances of provice below to region.
  //        https://unreserved.atlassian.net/browse/V2-683
  //

  const { nameShort, city, region } = place

  if (place.type === PlaceType.Country || place.type === PlaceType.Region) {
    return `${nameShort}`
  }

  if (place.type === PlaceType.City) {
    return `${nameShort}${region ? ', ' + region.nameAbbreviated : ''}`
  }

  if (
    [PlaceType.Street, PlaceType.Neighbourhood, PlaceType.SubNeighbourhood, PlaceType.MacroNeighbourhood].includes(
      place.type
    )
  ) {
    return `${nameShort}${city ? ', ' + city.nameShort : ''}${region ? ', ' + region.nameAbbreviated : ''}`
  }

  if (place.type === PlaceType.MetroArea) {
    return `${nameShort}${city ? ', ' + city.nameShort : ''}`
  }

  return '-'
}

const AUTOCOMPLETE_PLACE_MAPPING: {
  key: keyof PlaceAutoCompleteSuggestion
  type: PlaceType
}[] = [
  {
    key: 'regions',
    type: PlaceType.Region,
  },
  {
    key: 'cities',
    type: PlaceType.City,
  },
  {
    key: 'metroAreas',
    type: PlaceType.MetroArea,
  },
  {
    key: 'neighbourhoods',
    type: PlaceType.Neighbourhood,
  },
  {
    key: 'streets',
    type: PlaceType.Street,
  },
]

export function mapAutoCompleteSuggestionToInfo(suggestionData?: PlaceAutoCompleteSuggestion) {
  if (!suggestionData) {
    return []
  }

  return AUTOCOMPLETE_PLACE_MAPPING.reduce((memo: Partial<Place>[], item) => {
    const places = (suggestionData[item.key] as PlaceAutoCompleteSuggestionInfo[]) || []

    return [
      ...memo,
      ...places.map((place) => ({
        type: item.type,
        nameLong: place.nameLong,
        slug: place.slug,
      })),
    ]
  }, [])
}
