import { DEFAULT_LISTING_DIRECTORY } from './routing/constants'

export enum STATUS_TAB_LINK_IDS {
  SOLD = 'sold',
  FOR_SALE = 'forSale',
}

export const LISTING_STATUS_TAB_LINK_CONFIG = [
  {
    id: STATUS_TAB_LINK_IDS.FOR_SALE,
    key: 'for-sale',
    subTitle: 'home-page.listing-tab-sale-subtitle',
    href: `${DEFAULT_LISTING_DIRECTORY}/homes-for-sale-in-canada`,
    labelKey: 'home:recently.listed-title',
  },
  {
    id: STATUS_TAB_LINK_IDS.SOLD,
    key: 'sold',
    subTitle: 'home-page.listing-tab-sold-subtitle',
    href: `${DEFAULT_LISTING_DIRECTORY}/homes-sold-in-canada`,
    labelKey: 'home:recently.sold-title',
  },
]
