import { ReactNode } from 'react'
import Link from 'next/link'
import { twMerge } from 'tailwind-merge'

export interface TileLinkProps {
  id: string
  href: string
  name: string
  children: ReactNode
  containerClassname?: string
}

export function TileLink({ id, href, name, children, containerClassname }: TileLinkProps) {
  return (
    <Link
      data-testid={id}
      href={href}
      key={id}
      className={twMerge(
        'border-shades-200 col-span-6 block cursor-pointer rounded border bg-white p-4',
        containerClassname
      )}
    >
      <div className="truncate font-bold">{name}</div>
      <div className="text-sm leading-[24px]">{children}</div>
    </Link>
  )
}

export default TileLink
