import { Maybe, ResidentialPropertyForSaleListing } from '@unreserved-frontend-v2/api/generated/graphql/types'
import { twMerge } from 'tailwind-merge'
import { ListingCard } from '../listing-card/listing-card'

export enum ListingCardsGroupVariant {
  ROW = 'row', // 1 Row that scrolls sideways
  COLUMNS = 'columns', // responsive columns
}

export interface ListingCardsGroupProps {
  title: string
  isMobile?: boolean
  className?: string
  listings: Maybe<ResidentialPropertyForSaleListing[]> | undefined
  variant?: ListingCardsGroupVariant
  cardClassName?: string
  headerTagType?: keyof JSX.IntrinsicElements
  listingCardHeaderTagType?: keyof JSX.IntrinsicElements
}

export function ListingCardsGroup({
  title,
  isMobile = false,
  className = '',
  listings,
  variant = ListingCardsGroupVariant.ROW,
  cardClassName,
  headerTagType: HeaderTag = 'h3',
  listingCardHeaderTagType = 'h2',
}: ListingCardsGroupProps) {
  return listings?.length !== 0 ? (
    <div className={className}>
      <HeaderTag className="text-shades-600 pb-4 text-lg font-bold leading-6">{title}</HeaderTag>

      {/** Added snap scroll behaviour for now until proper scrolling is used **/}
      {variant === ListingCardsGroupVariant.ROW ? (
        <div
          className={twMerge(
            isMobile ? 'snap-x snap-mandatory grid-cols-1' : '',
            `grid-row-1 flex gap-4 overflow-x-auto`
          )}
        >
          {listings?.map((listing, i) => {
            return (
              <ListingCard
                key={listing.id}
                listing={listing}
                contentClassName={twMerge(isMobile ? 'w-[342px] snap-center snap-always' : 'w-[288px]', cardClassName)}
                showLikable={true}
                isUsingRedirectButton={isMobile}
                headerTagType={listingCardHeaderTagType}
              />
            )
          })}
        </div>
      ) : null}
      {variant === ListingCardsGroupVariant.COLUMNS ? (
        <div className="grid grid-cols-12 gap-x-4">
          {listings?.map((listing, i) => {
            return (
              <ListingCard
                key={listing.id}
                listing={listing}
                containerClassName={twMerge('col-span-12 mb-6 md:mb-4', cardClassName)}
                showLikable={true}
                isUsingRedirectButton={isMobile}
                headerTagType={listingCardHeaderTagType}
              />
            )
          })}
        </div>
      ) : null}
    </div>
  ) : null
}

export default ListingCardsGroup
