import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useTranslation from 'next-translate/useTranslation'
import { useState } from 'react'

import { Button } from '@unreserved-frontend-v2/ui/button/button'
import { FlexCol } from '@unreserved-frontend-v2/ui/flex/flex-col'
import { getMiddleItemInArray } from '@unreserved-frontend-v2/utils/arrays'
import { currency } from '@unreserved-frontend-v2/utils/currency'

import { ONE_MILLION, SLIDER_MAX, SLIDER_MIN, SLIDER_OPTIONS, SLIDER_STEP, UNRESERVED_FIXED_FEE } from './constants'
import { getSavingsForBuyersFee } from './utils'

export const SLIDER_CLASSES = `
    w-full appearance-none bg-transparent cursor-pointer
    [&::-webkit-slider-runnable-track]:rounded-full 
    [&::-webkit-slider-runnable-track]:bg-white
    [&::-webkit-slider-runnable-track]:h-[16px] 
    [&::-webkit-slider-thumb]:h-[26px] 
    [&::-webkit-slider-thumb]:w-[26px] 
    [&::-webkit-slider-thumb]:-mt-[8px] 
    [&::-webkit-slider-thumb]:border-[3px] 
    [&::-webkit-slider-thumb]:border-solid 
    [&::-webkit-slider-thumb]:border-primary-800 
    [&::-webkit-slider-thumb]:box-content 
    [&::-webkit-slider-thumb]:appearance-none 
    [&::-webkit-slider-thumb]:rounded-full 
    [&::-webkit-slider-thumb]:bg-white 
    [&::-webkit-slider-thumb]:drop-shadow-none 
    [&::-webkit-slider-thumb]::[-webkit-appearance]:[none] 

    [&::-moz-range-track]:rounded-full 
    [&::-moz-range-track]:bg-white 
    [&::-moz-range-track]:h-[16px] 
    [&::-moz-range-thumb]:h-[26px] 
    [&::-moz-range-thumb]:w-[26px] 
    [&::-moz-range-thumb]:-mt-[8px] 
    [&::-moz-range-thumb]:border-[3px] 
    [&::-moz-range-thumb]:border-solid 
    [&::-moz-range-thumb]:border-primary-800 
    [&::-moz-range-thumb]:box-content 
    [&::-moz-range-thumb]:appearance-none 
    [&::-moz-range-thumb]:rounded-full 
    [&::-moz-range-thumb]:bg-white 
    [&::-moz-range-thumb]:drop-shadow-none 
  `

export interface SavingsWidgetLiteProps {
  containerClassName?: string
}

export const SavingsWidgetLite = ({ containerClassName }: SavingsWidgetLiteProps) => {
  const { t } = useTranslation()
  const [sliderValue, setSliderValue] = useState(getMiddleItemInArray(SLIDER_OPTIONS) as number)
  const [saving, setSaving] = useState(getSavingsForBuyersFee(sliderValue, 0))

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const price = SLIDER_OPTIONS[parseFloat(event.target.value)]

    setSliderValue(price)

    // We want to display the savings "up to", which is the maximum savings when the buyer agent fee is 0%
    setSaving(getSavingsForBuyersFee(price, 0))
  }

  return (
    <FlexCol className={containerClassName}>
      <div className="rounded-t bg-primary-800 px-1 pt-6 pb-[18px] text-center text-white md:px-0 md:pb-1">
        {t('listings:savings-widget.title')
          .split('\n')
          .map((title, idx) => {
            return (
              <h2 key={idx} className="text-xl font-bold leading-[30px] md:pt-2">
                {title}
              </h2>
            )
          })}
      </div>
      <div className="rounded-b bg-primary-800 px-6 pb-6 text-center text-white">
        <div className="pb-4.5 text-[40px] font-bold leading-[36px] md:pb-0 md:pt-[22px] md:text-[50px]">
          {currency(UNRESERVED_FIXED_FEE)}
        </div>

        <div className="mt-7 mb-3">
          <input
            defaultValue={SLIDER_OPTIONS.indexOf(sliderValue)}
            className={SLIDER_CLASSES}
            type="range"
            id="propertyPrice"
            name="propertyPrice"
            min={SLIDER_MIN}
            max={SLIDER_MAX}
            step={SLIDER_STEP}
            onChange={handleSliderChange}
            data-testid="slider"
            list="sliderValues"
          />
          <label className="hidden" htmlFor="propertyPrice">
            {t('listings:savings-widget.property-price')}
          </label>
        </div>

        <div className="mb-[18px] grid grid-cols-2 leading-[30px] md:mb-[17px]">
          <div className="col-span-1 border-r border-dashed border-white">
            {t('listings:savings-widget.property-price')}
            <div data-testid="price" className="text-[24px] font-bold leading-[36px]">
              {sliderValue >= ONE_MILLION
                ? `${currency(sliderValue / ONE_MILLION)}M`
                : `${currency(sliderValue / 1000)}K`}
            </div>
          </div>
          <div className="col-span-1">
            {t('listings:savings-widget.save')}
            <div data-testid="saving" className="text-[24px] font-bold leading-[36px]">
              {saving}
            </div>
          </div>
        </div>

        <Button variant="tertiary" href="/learn-more/selling-simplified">
          {t('listings:savings-widget.cta')}
          <FontAwesomeIcon className="pl-4" icon={faArrowRight} transform={{ size: 20 }} />
        </Button>
      </div>
    </FlexCol>
  )
}
