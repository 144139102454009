import { useCallback, useMemo } from 'react'
import { GraphQLClient } from 'graphql-request'
import { API_ENDPOINT } from '../constants'
import { ModifyResidentialPropertyListingLikeInput } from '../generated/graphql/types'
import { useModifyResidentialPropertyListingLikeMutation } from '../generated/graphql/modify-residential-property-listing-like.graphql'
import { MutationErrorResponse } from '../mutations/mutation-error-response'

// ------------------------------------------------------------------------------------------------------------
//
// NOTE:  Our hooks make use of useMemo() because they all return an object and if we return a new object
//        every time (even when the data doesn't change) any useEffects() that are dependent on this data
//        will re-run and potentially cause infinite render loops.
//
// ------------------------------------------------------------------------------------------------------------

type TError = MutationErrorResponse

export function useModifyResidentialPropertyListingLike(): {
  mutate: (input: ModifyResidentialPropertyListingLikeInput) => void
  isSuccess: boolean
} {
  const result = useModifyResidentialPropertyListingLikeMutation<TError>(new GraphQLClient(API_ENDPOINT))
  const { mutate, isSuccess } = result

  const doMutation = useCallback(
    function doMutation(input: ModifyResidentialPropertyListingLikeInput) {
      return mutate({ input })
    },
    [mutate]
  )

  return useMemo(() => {
    return {
      mutate: doMutation,
      isSuccess,
    }
  }, [doMutation, isSuccess])
}
