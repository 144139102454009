import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import dynamic from 'next/dynamic'
import { twMerge } from 'tailwind-merge'

import Button, { ButtonProps } from '../button/button'

const FontAwesomeIcon: React.ComponentType<FontAwesomeIconProps> = dynamic(
  () => import('@fortawesome/react-fontawesome').then((mod) => mod.FontAwesomeIcon),
  {
    ssr: false,
  }
)

export interface IconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: IconProp
  className?: string
  iconClassName?: string
  targetPaddingClassName?: string
  children?: React.ReactNode
}

export function IconButton({
  icon,
  iconClassName,
  className = '',
  targetPaddingClassName,
  children,
  ...otherProps
}: IconButtonProps & ButtonProps) {
  return (
    <Button
      className={twMerge(`flex w-10 items-center justify-center [&>svg]:h-[18px]`, className)}
      targetPaddingClassName={targetPaddingClassName}
      variant="ghost"
      {...otherProps}
    >
      {icon ? <FontAwesomeIcon icon={icon} className={iconClassName} /> : null}
      {children}
    </Button>
  )
}

export default IconButton
