import { currency } from '@unreserved-frontend-v2/utils/currency'

import { FULL_SELLER_FEE_PERCENT, HST_MULTIPLIER, UNRESERVED_FIXED_FEE } from './constants'

export const getSavingsForBuyersFee = (listingPrice: number, buyersFee: number) => {
  const fullFee = listingPrice * FULL_SELLER_FEE_PERCENT * HST_MULTIPLIER
  const discountedFee = (UNRESERVED_FIXED_FEE + listingPrice * buyersFee) * HST_MULTIPLIER

  return currency(fullFee - discountedFee)
}
