import {
  DEFAULT_LISTING_DIRECTORY,
  DEFAULT_LISTING_PAGE,
  DEFAULT_URL_PLACE_PREFIX,
} from '@unreserved-frontend-v2/modules/listings/utils/routing/constants'
import { getQueryParamForGeoInput } from '@unreserved-frontend-v2/modules/listings/utils/routing/object-filter-utils'
import { FilterChangeEvent } from '@unreserved-frontend-v2/ui/schema-table/models'

export const getGeoUrl = (event: FilterChangeEvent[]) => {
  const geo = event.find((event) => event.key === 'geo')

  if (geo?.value) {
    const geoParamUrl = getQueryParamForGeoInput(geo.value)
    return `${DEFAULT_LISTING_PAGE}?${geoParamUrl}&sort=NearToFar`
  }

  const place = (event as FilterChangeEvent[]).find((event) => event.key === 'placeSlugs')

  if (place?.value) {
    return `${DEFAULT_LISTING_DIRECTORY}/${DEFAULT_URL_PLACE_PREFIX}-${place.value}`
  }
}
