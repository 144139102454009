import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { faHeart } from '@fortawesome/pro-solid-svg-icons'
import { useQueryClient } from '@tanstack/react-query'

import { useModifyResidentialPropertyListingLike } from '@unreserved-frontend-v2/api/hooks/use-modify-residential-property-listing-like'
import { IconButton } from '@unreserved-frontend-v2/ui/icon-button/icon-button'
import { RegisterOrSignInModal } from '../../../users/components/register-or-sign-in/register-or-sign-in-modal'
import { UserContext } from '../../../users/components/user-info-provider/user-context'

export interface LikeButtonProps {
  isLiked?: boolean | null
  listingId: string
  className?: string
  targetPaddingClassName?: string
  isMobile?: boolean
}

export const LikeButton = ({
  isLiked = false,
  listingId,
  className,
  isMobile,
  targetPaddingClassName,
}: LikeButtonProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { isLoggedIn } = useContext(UserContext)
  const [isLikedState, setIsLikedState] = useState(isLiked)
  const { isSuccess, mutate } = useModifyResidentialPropertyListingLike()
  const { asPath } = useRouter()

  const handleLikeButtonClick = () => {
    if (isLoggedIn) {
      mutate({
        listingId,
        like: !isLikedState,
      })
    } else {
      setIsModalOpen(true)
    }
  }

  useEffect(() => {
    setIsLikedState(isLiked)
  }, [isLiked])

  useEffect(() => {
    if (isSuccess) {
      setIsLikedState((prev) => !prev)
      queryClient.invalidateQueries(['GetListingLite'])
    }
  }, [isSuccess, queryClient])

  return (
    <div data-testid="like-button" className={className}>
      {!isMobile || isLoggedIn ? (
        <>
          <IconButton
            data-testid={isLikedState ? 'liked' : 'not-liked'}
            variant={isLikedState ? 'primary' : 'ghost'}
            onClick={handleLikeButtonClick}
            icon={faHeart}
            className="text-lg"
            targetPaddingClassName={targetPaddingClassName}
            aria-label={t('listings:like-this-listing')}
          />
          <RegisterOrSignInModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />{' '}
        </>
      ) : (
        <Link rel="nofollow" href={`/register-or-sign-in${asPath ? `?redirectTo=${encodeURIComponent(asPath)}` : ''}`}>
          <IconButton variant={isLikedState ? 'primary' : 'ghost'} icon={faHeart} className="text-lg" />
        </Link>
      )}
    </div>
  )
}
