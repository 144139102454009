import Link from 'next/link'
import { twMerge } from 'tailwind-merge'
import { HTMLAttributeAnchorTarget, HTMLProps, ReactNode } from 'react'
import { ImageWithShimmerOptimized } from '@unreserved-frontend-v2/ui/image-with-shimmer/image-with-shimmer-optimized'
import { InfoSetMobile } from '@unreserved-frontend-v2/ui/info-set/info-set-mobile'
import { HousePlaceholderIcon } from '@unreserved-frontend-v2/ui/icons/house-placeholder'
import {
  Maybe,
  PropertyListingStatus,
  ResidentialPropertyForSaleListing,
} from '@unreserved-frontend-v2/api/generated/graphql/types'

import { LikeButton } from '../like-button/like-button'
import { useCompactListingInfo } from '../../hooks/use-compact-listing-info/use-compact-listing-info'
import { useRestrictedListing } from '../use-restricted-listing'
import { formatListingDetailsUrl } from '../../utils/routing/parse-format-details-slugged-values'
import { RegisterOrSignInPrompt } from '../../../users/components/register-or-sign-in/register-or-sign-in-prompt'
import { ListingPreviewerBadges, PreviewerBadges } from '../listing-previewer/previewer-badges'
import { getSimpleAddress } from '@unreserved-frontend-v2/geolocation/utils/utils'

export type ListingCardProps = HTMLProps<ReactNode> &
  React.HTMLAttributes<HTMLDivElement> & {
    listing: Maybe<ResidentialPropertyForSaleListing> | undefined
    containerClassName?: string
    contentClassName?: string
    imageContainerClassName?: string
    target?: HTMLAttributeAnchorTarget
    showLikable?: boolean
    showBadges?: boolean
    isUsingRedirectButton?: boolean
    topRowBadges?: PreviewerBadges[] | null
    bottomRowBadges?: PreviewerBadges[] | null
    headerTagType?: keyof JSX.IntrinsicElements
  }
const restrictedUnderlay =
  'after:content-[""] after:w-full after:h-full after:opacity-40 after:absolute after:top-0 after:bg-shades-1000'

export function ListingCard({
  listing: rawListing,
  target,
  showLikable,
  showBadges,
  imageContainerClassName = '',
  isUsingRedirectButton,
  containerClassName,
  contentClassName,
  headerTagType = 'h2',
  topRowBadges = [PreviewerBadges.NEW, PreviewerBadges.DAYS_ON_MARKET, PreviewerBadges.SOLD_DATE],
  bottomRowBadges = [PreviewerBadges.INQUIRED, !showLikable ? PreviewerBadges.LIKED : null].filter(
    Boolean
  ) as PreviewerBadges[],
  ...htmlPropsRest
}: ListingCardProps) {
  const { data: listing, isRestricted } = useRestrictedListing(rawListing as ResidentialPropertyForSaleListing)
  const listingImage = listing?.media?.[0]?.originUrl || ''

  const { title, caption, infoItems } = useCompactListingInfo(
    listing as ResidentialPropertyForSaleListing,
    isRestricted
  )

  const content = (
    <div className={twMerge('relative overflow-hidden rounded-md bg-white', contentClassName)}>
      <div className={twMerge('relative h-[180px]', isRestricted ? restrictedUnderlay : '')}>
        {listingImage ? (
          <ImageWithShimmerOptimized
            src={listingImage}
            width={288}
            height={180}
            className={imageContainerClassName}
            isRestricted={isRestricted}
            alt={getSimpleAddress(listing?.property.address)}
          />
        ) : (
          <div className="bg-shades-25 border-shades-200 flex h-full w-full flex-col items-center rounded-t border pt-12 text-center">
            <HousePlaceholderIcon />
          </div>
        )}
        {isRestricted ? (
          <RegisterOrSignInPrompt
            className="px-auto w-full px-4"
            isSold={listing.listingStatus === PropertyListingStatus.Sold}
            useRedirectButton={isUsingRedirectButton}
          />
        ) : null}
        {!isRestricted && showBadges ? (
          <>
            {/* Top Row */}
            {topRowBadges ? (
              <ListingPreviewerBadges
                listing={listing}
                key="top-badges"
                className="absolute top-2 mt-0 ml-0 w-full pr-4 pl-2"
                enabledBadges={topRowBadges}
              />
            ) : null}
            {/* Bottom Row */}
            {bottomRowBadges ? (
              <ListingPreviewerBadges
                listing={listing}
                key="bottom-badges"
                className="absolute bottom-4 mt-0 ml-0 w-full pr-4 pl-1.5"
                enabledBadges={bottomRowBadges}
              />
            ) : null}
          </>
        ) : null}
      </div>
      <div className="border-shades-200 min-h-[97px] rounded-b-md border-x border-b px-4 pb-4 pt-[0.7rem]">
        <InfoSetMobile
          title={title}
          caption={caption}
          infoItems={infoItems}
          isRestricted={isRestricted}
          headerTagType={headerTagType}
        />
      </div>
    </div>
  )

  return (
    <div
      className={twMerge('relative', containerClassName)}
      {...(htmlPropsRest as React.HTMLAttributes<HTMLDivElement>)}
    >
      {!isRestricted ? (
        <Link href={formatListingDetailsUrl(listing)} target={target}>
          {content}
        </Link>
      ) : (
        content
      )}
      {!isRestricted && showLikable ? (
        <LikeButton
          className="absolute top-2 right-2"
          targetPaddingClassName="p-1"
          isLiked={listing?.liked}
          listingId={listing?.id as string}
        />
      ) : null}
    </div>
  )
}

export default ListingCard
