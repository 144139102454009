import {
  ResidentialType,
  PropertyListingStatus,
  ListingType,
} from '@unreserved-frontend-v2/api/generated/graphql/types'
import { FilterSelections } from '@unreserved-frontend-v2/ui/schema-table/models'

export const FIRST_PAGE = 0
// TODO: When we support other countries, this needs to be that country's slug
export const DEFAULT_COUNTRY_SLUG = 'canada'
export const DEFAULT_LISTING_DIRECTORY = 'real-estate'
export const DEFAULT_URL_PLACE_PREFIX = 'homes-for-sale-in'
export const DEFAULT_LISTING_SLUG = `${DEFAULT_URL_PLACE_PREFIX}-${DEFAULT_COUNTRY_SLUG}`
export const DEFAULT_LISTING_PAGE = `/${DEFAULT_LISTING_DIRECTORY}/${DEFAULT_LISTING_SLUG}`
export const DEFAULT_CONTACT_NUMBER = '800-591-0566'

// My Listings
export const DEFAULT_MY_LISTING_PAGE = `/my-account/my-listings`

// NOTE: Setting this back to 0 because it creates a noticeable delay when changing a filter. Most filters cannot be
// changed very quickly in succession (for example selects), so the debouncing should generally be controlled at the
// individual filter level like autocomplete or slider
export const FILTER_CHANGE_DEBOUNCE = 0

export const DEFAULT_PRIMARY_FILTER_SELECTIONS: FilterSelections = {
  geo: null,
  bounds: null,
  boundsRestricted: true,
  city: 'ALL',
  keyword: '',
  listingTypes: [ListingType.ForSale],
  listingStatuses: [PropertyListingStatus.Active, PropertyListingStatus.ConditionallySold],
  liked: false,
  inquired: false,
  tags: null,
}

export const DEFAULT_PRIMARY_LIKED_FILTER_SELECTIONS: FilterSelections = {
  ...DEFAULT_PRIMARY_FILTER_SELECTIONS,
  listingStatuses: [PropertyListingStatus.Active, PropertyListingStatus.Sold, PropertyListingStatus.ConditionallySold],
  liked: true,
  placeSlugs: [DEFAULT_COUNTRY_SLUG],
}

export const DEFAULT_PRIMARY_INQUIRED_FILTER_SELECTIONS: FilterSelections = {
  ...DEFAULT_PRIMARY_FILTER_SELECTIONS,
  listingStatuses: [PropertyListingStatus.Active, PropertyListingStatus.Sold, PropertyListingStatus.ConditionallySold],
  inquired: true,
  placeSlugs: [DEFAULT_COUNTRY_SLUG],
}
export const DEFAULT_SECONDARY_FILTER_SELECTIONS: FilterSelections = {
  sort: 'NewToOld',
  residentialTypes: 'ALL',
  residentialSubTypes: null,
  totalBedroomsGTE: 'ALL',
  totalBathroomsGTE: 'ALL',
  priceLTE: 'ALL',
  priceGTE: 'ALL',
  squareFootageLTE: 'ALL',
  squareFootageGTE: 'ALL',
  totalParkingGTE: 'ALL',
}

export const DEFAULT_FILTER_SELECTIONS = {
  ...DEFAULT_PRIMARY_FILTER_SELECTIONS,
  ...DEFAULT_SECONDARY_FILTER_SELECTIONS,
}

// A list of all pages/.../[route_param]/ structures used in the app for query scraping
export enum ROUTE_BASED_PARAMS {
  LISTING = 'listing',
  ID = 'id',
}

/**
 * Supported api input values
 */
export enum SupportedSlugInputValues {
  ResidentialTypes = 'residentialTypes',
  ListingStatuses = 'listingStatuses',
  PlaceSlugs = 'placeSlugs',
}

/**
 * An ordered array of slugged filters, used to build out/parse slug-based filters
 * structured as: {residentialType}-{listingStatus}-in-{place.slugId}
 **/
export type SluggedFilterEntry = {
  key: SupportedSlugInputValues
  values: {
    urlValue?: string
    queryValue: ResidentialType | PropertyListingStatus | PropertyListingStatus[] | string | null | undefined
  }[]
  suffix?: string
}

export const SLUGGED_FILTERS: SluggedFilterEntry[] = [
  {
    key: SupportedSlugInputValues.ResidentialTypes,
    values: [
      { urlValue: 'homes', queryValue: undefined },
      { urlValue: 'houses', queryValue: ResidentialType.House },
      { urlValue: 'condos', queryValue: ResidentialType.Apartment },
      // Specialized keys denoting different format when used for details page
      { urlValue: 'house', queryValue: `details-${ResidentialType.House}` },
      { urlValue: 'condo', queryValue: `details-${ResidentialType.Apartment}` },
    ],
  },
  {
    key: SupportedSlugInputValues.ListingStatuses,
    values: [
      { urlValue: 'for-sale', queryValue: [PropertyListingStatus.Active, PropertyListingStatus.ConditionallySold] },
      { urlValue: 'sold', queryValue: PropertyListingStatus.Sold },
    ],
    suffix: 'in',
  },
  {
    key: SupportedSlugInputValues.PlaceSlugs,
    values: [
      // Only if the `placeSlugs` is explicitly undefined or null, use `DEFAULT_COUNTRY_SLUG`, else use whatever value is given
      { urlValue: undefined, queryValue: null },
      { urlValue: DEFAULT_COUNTRY_SLUG, queryValue: undefined },
    ],
  },
]

/** A helper method to determine whether or not a listing status can be consider `forSale` */
export const isForSale = (listingStatus: PropertyListingStatus) =>
  [PropertyListingStatus.Active, PropertyListingStatus.ConditionallySold].includes(
    listingStatus as PropertyListingStatus
  )
